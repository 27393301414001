<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Cama - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>

                <b-col md="3">
                  <b-row>
                    <b-col md="4"></b-col>
                    <b-col md="4"><b-card-img class="" :src="url_base + bed.photo"></b-card-img></b-col>
                  </b-row>
          
                  <b-form-group>
                    <b-form-file @change="onFileChange" accept="image/*" v-model="photo" placeholder="Seleccione un foto..." drop-placeholder="Suelta la imagen aquí..."></b-form-file>
                    <small v-if="errors.photo" class="form-text text-danger">Seleccione un foto</small>
                  </b-form-group>
                </b-col>

                <b-col md="9">
                  <b-row>

                        <b-col md="10">
                          <b-form-group label="Nombre:">
                            <b-form-input  type="text" v-model="bed.name" ></b-form-input>
                            <small v-if="errors.name" class="form-text text-danger">Ingrese un nombre</small>
                          </b-form-group>
                        </b-col>

                        <b-col md="2">
                          <b-form-group label="Estado:">
                            <b-form-select v-model="bed.state" :options="state"></b-form-select>
                          </b-form-group>
                        </b-col>

                        
                        <b-col md="5"></b-col>
                        <b-col md="2">
                          <b-button type="submit" class="form-control"  variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button >
                        </b-col>

                  </b-row>

                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  props: ["id_bed"],
  data() {
    return {
      isLoading: false,
      module: "bed",

      role: 3,
      bed: {
        id_bed: "",
        name: "",
        photo: "",
        photo_change: "",
        state: 1,
      },
      photo: null,
      state:[
        {value:0,text:'Inactivo'},
        {value:1,text:'Activo'},
      ],
      
      errors: {
        name: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewBed();
  },
  methods: {
    ViewBed,
    onFileChange,
    EditBed,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};
function ViewBed() {
  let id_bed = je.decrypt(this.id_bed);
  let me = this;
  let url = this.url_base + "bed/view/" + id_bed;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module,role: this.role,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.bed.id_bed = response.data.result.id_bed;
        me.bed.name = response.data.result.name;
        me.bed.photo = response.data.result.photo;
        me.bed.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}
function onFileChange(e) {
  this.bed.photo_change = e.target.files[0];
}

function EditBed(me) {
  let url = me.url_base + "bed/edit";
  let data = new FormData();
  data.append("id_bed", this.bed.id_bed);
  data.append("name", this.bed.name);
  data.append("photo", this.bed.photo_change);
  data.append("state", this.bed.state);
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: 2,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.bed.photo = response.data.result.photo;
        me.photo = null;
        Swal.fire({ icon: 'success', text: 'Se ha modificado el servicio', timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

/**
 * 
 */
function Validate() {

  this.errors.name = this.bed.name.length == 0 ? true : false;

  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de registrar el servicio ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.EditBed(me);
      }
    });

  }

}
</script>
